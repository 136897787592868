




















import Vue from "vue";
import { Component, PropSync } from "vue-property-decorator";

@Component
export default class EmptyQuery extends Vue {
  @PropSync("email", { default: () => "" }) userEmail!: string;
  @PropSync("tokenProp", { default: () => "" }) token!: string;
  confirm() {
    this.$emit("confirm");
  }
}
